import React, { useState, useEffect } from "react";
import CallApi from "../components/Api";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message
} from "@chatscope/chat-ui-kit-react";

const ConversationLogView = (props) => {
    const [messages, setMessages] = useState([]);    

    const get_full_log = async () => {
        let params = {
            user_id: props.selectedConversation.user_id,
            start_timestamp: props.selectedConversation.start_timestamp,
            end_timestamp: props.selectedConversation.last_timestamp,
            limit: 1000
        };
        const resp = await CallApi("/conversation_history", "GET", params);
        let resp_messages = [];
        resp?.forEach((item) => {
            let item_messages = item.messages || [];
            item_messages?.forEach((message) => {
                if (message.role === "assistant") {
                    let content = message.content || "";
                    let className = "";
                    if (content !== "") {
                        content += "\n";
                    }
                    if (message.function_call) {
                        content += "function: " + message.function_call?.name + "\n" + message.function_call?.arguments;
                        className = "function-bubble";
                    }
                    resp_messages.push({
                        message: content,
                        sentTime: new Date((item.timestamp || 0) * 1000).toLocaleString(),
                        direction: "incoming",
                        className: className
                    });
                }
                else if (message.role === "user" && message.content !== null) {
                    resp_messages.push({
                        message: message.content,
                        sentTime: new Date((item.timestamp || 0) * 1000).toLocaleString(),
                        direction: "outgoing",
                    });
                }
                else if (message.role === "function") {
                    let content = "function returned: " + message?.name + "\n";
                    content += message.content;
                    resp_messages.push({
                        message: content,
                        sentTime: new Date((item.timestamp || 0) * 1000).toLocaleString(),
                        direction: "outgoing",
                        className: "function-bubble"
                    });
                }
            });
        });
        setMessages(resp_messages);
    };

    useEffect(() => {
        get_full_log();
    }, []);

    if (!props.selectedConversation) {
        return null;
    }
    
    return (
        <div className="col-6">
            <h1>Conversation Logs</h1>
            <button onClick={() => props.setView("index")} className="btn btn-primary">Back</button><br/>
            <label>User ID:</label>&nbsp;<b>{props.selectedConversation.user_id}</b><br/>
            <label>Conversation ID:</label>&nbsp;<b>{props.selectedConversation.conversation_id}</b><br/>
            <label>Start:</label>&nbsp;<b>{new Date((props.selectedConversation.start_timestamp || 0) * 1000).toLocaleString()}</b><br/>
            <label>End:</label>&nbsp;<b>{new Date((props.selectedConversation.last_timestamp || 0) * 1000).toLocaleString()}</b><br/>
            <MainContainer>
                <ChatContainer style={{maxHeightheight: "80vh"}}>
                    <MessageList>
                        {messages.map((message, index) => (
                            <Message key={index} model={message} className={message.className} />   
                        ))}
                    </MessageList>
                </ChatContainer>
            </MainContainer>
        </div>
    );
  };
  
  export default ConversationLogView;
  