import React, { useState, useEffect } from "react";
import CallApi from '../components/Api';
import Alert from '../components/Alert';
import ClipLoader from "react-spinners/ClipLoader";

const PromptPage = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState("");  
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      refresh_prompts();
  }, []);

  const refresh_prompts = async () => {
    setLoading(true);    
    try {        
        const resp = await CallApi("/prompt", "GET");
        const sorted_prompts = [...resp?.prompts].sort((a, b) => a?.name?.S.localeCompare(b?.name?.S));
        setPrompts(sorted_prompts);        
    }
    catch (error) {
        console.log('Error in refresh_prompts:', error);
        setAlert({"type": "error", "message": "Could not get prompts."});
    }
    setLoading(false);
  }

  const handleSave = async () => {    
    setAlert({});
    try {        
        await CallApi("/prompt", "POST", { 
            "name": selectedName,
            "value": selectedPrompt});
        refresh_prompts();
        setAlert({"type": "success", "message": "Prompt saved"});
    }
    catch (error) {
        console.log('Error in handleSave:', error);
        setAlert({"type": "error", "message": "Could not save prompt."});
    }
  }

  const handleSelectedName = (name) => {
    setAlert({});
    setSelectedName(name);    
    setSelectedPrompt(prompts.find(x => x?.name?.S === name)?.value?.S);
  }

  return (    
    <div className="row h-100">
      <div className="col-md-3" style={{display: "flex",  flexDirection: "column",  height: "100%"}}>
        <ClipLoader loading={loading} size={150} aria-label="Loading..." />
        <table className="table table-bordered">
          <tbody>
            {prompts.map((item, index) => (
              <tr key={index}>
                <td className={(selectedName === item?.name?.S ? "prompt-active" : "")} >
                  <button className="btn btn-link"
                    onClick={() => handleSelectedName(item?.name?.S)}>{item?.name?.S}</button>                    
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div hidden={selectedName === ""} className="col-md-9">
        <Alert alert={alert} />
        <textarea className="form-control" placeholder="Enter prompt here"
          rows="30" value={selectedPrompt}
          onChange={(e) => setSelectedPrompt(e.target.value)}>              
        </textarea>
        <br/>
        <button className="btn btn-success" onClick={() => handleSave()}>Save</button>
      </div>      
    </div>
  );
};

export default PromptPage;