import React, { useState, useEffect, useRef } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { useWhisper } from '@chengsokdara/use-whisper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { SendButton, Button } from "@chatscope/chat-ui-kit-react/dist/cjs/Buttons";
import CallApi from './Api';
import { TypingIndicator } from "@chatscope/chat-ui-kit-react";
import userContext from "../UserContext";


const Chat = (props) => {
    const inputRef = useRef();
    const [msgInputValue, setMsgInputValue] = useState("");
    const [messages, setMessages] = useState([]);
    const [clearing, setClearing] = useState(false);
    const [sending, setSending] = useState(false);    

    const {
        recording,
        //speaking,
        //transcribing,
        transcript,
        //pauseRecording,
        startRecording,
        stopRecording,
    } = useWhisper({
        apiKey: userContext.openai_key,
        streaming: true,
        timeSlice: 1_000, // 1 second
        removeSilence: true,        
        //onChange: (data) => { console.log(data); }
    });
  
    useEffect(() => {
        if (transcript.text) {
            setMsgInputValue(msgInputValue + " " + transcript.text);
        }
    }, [msgInputValue, transcript.text]);

    useEffect(() => {
        refresh_messages();
    }, []);

    const refresh_messages = async (force_create=false) => {
        //console.log("refreshing messages");
        if (!props.userId)
            return;
        try {
            let conv = await CallApi("/get_or_create_user_conversation", "POST", {"user_id": props.userId, 
                "force_create": force_create});
            if (!conv || !conv?.start_timestamp || conv?.created) {
                setMessages([]);
                return;
            }
            let last_summary = "";
            let last_total_tokens = 0;
            let conversation_history = await CallApi("/conversation_history", "GET", {"user_id": props.userId, 
                "start_timestamp": conv?.start_timestamp});                
            let resp_messages = [];
            conversation_history?.forEach((item) => {
                let item_messages = item.messages || [];
                item_messages?.forEach((message) => {
                    if (message.role === "assistant" && message.content !== null) {
                        resp_messages.push({
                            message: message.content,
                            sentTime: new Date((item.timestamp || 0) * 1000).toLocaleString(),
                            direction: "incoming",
                        });
                    }
                    else if (message.role === "user" && message.content !== null) {
                        resp_messages.push({
                            message: message.content,
                            sentTime: new Date((item.timestamp || 0) * 1000).toLocaleString(),
                            direction: "outgoing",
                        });
                    }
                });
                last_summary = item.summary;
                last_total_tokens = item.total_tokens;
            });                
            props.setCallInfo(<>
                <b>Tokens for last message:</b> {last_total_tokens}<br/>
                <b>Conversation summary:</b> {last_summary}<br/>
            </>);
            setMessages(resp_messages);                     
        }
        catch (error) {
            console.log('Error in refresh_messages:', error);
        }
    };

    const handleRecord = () => {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    const update_products_in_cart = async (current_cart, products_to_add=[], products_to_remove=[]) => {        
        let new_cart = [...current_cart];
        products_to_add.forEach((product) => {
            //console.log("product:", product);
            if (!new_cart.find((item) => item.product_code === product.product_code))
                new_cart.push(product);
            else {
                new_cart = new_cart.map((item) => {
                    if (item.product_code === product.product_code) {
                        item.quantity += product.quantity;
                    }
                    return item;
                });
            }
        });
        products_to_remove.forEach((product) => {
            new_cart = new_cart.map((item) => {
                if (item.product_code === product.product_code) {
                    item.quantity -= product.quantity;
                }
                return item;
            });
        });        
        new_cart = new_cart.filter((item) => item.quantity > 0);        
        props.setCartItems(new_cart);
        return new_cart;
    }

    const validate_products = async (products_to_add, products_to_remove) => {
        let valid = true;
        let errorMessages = [];
        if (products_to_add) {
            products_to_add.forEach((product) => {
                if (!product.product_code.startsWith("A")) {
                    valid = false;
                    errorMessages.push(product.product_code + " is invalid. Search for a valid product code.");
                }
            });
        }        
        return {valid, errorMessages};
    }

    const lookup_products = async (products) => {                
        const resp_products = await CallApi("/lookup_products", "POST", products);
        if (resp_products) {
            products.forEach((product) => {
                let resp_product = resp_products.find((item) => item.product_code === product.product_code);
                if (resp_product) {
                    product.manufacturer = resp_product.manufacturer;
                    product.label = resp_product.label;
                    product.sub_label = resp_product.sub_label;
                    product.category = resp_product.category;
                    product.brand = resp_product.brand;
                    product.bmc = resp_product.bmc;
                }
            });
        }
        return products;
    };

    const send_message = async (message, current_cart, new_messages, stats) => {
        const body = {"user_id": props.userId, "message": message, "cart": props.cartItems};
        const resp = await CallApi("/conversation", "POST", body);
        //console.log("resp:", resp);
        stats.summary = resp?.summary || "";
        stats.total_tokens += resp?.total_tokens || 0;
        stats.num_calls += resp?.num_calls || 0;
        stats.user_preferences = resp?.user_preferences || "";
        for (const message of resp?.messages || []) {
            if (message.role === "assistant" && message.content) {
                new_messages.push({
                    message: message.content,
                    sentTime: "just now",
                    direction: "incoming"                        
                });
            }
            if (message?.function_call) {
                // if there is a function call, execute it
                // then send the result back to the server
                // and add the new response to the messages                
                if (message.function_call?.name === "update_products_in_cart") {
                    let args = {};
                    let valid = true;
                    let errorMessages = [];
                    try {
                        args = JSON.parse(message.function_call?.arguments);
                    } catch (error) {
                        ({ valid, errorMessages } = { valid: false, errorMessages: ["Unable to parse JSON arguments"] });                        
                    }
                    if (valid) {
                        ({ valid, errorMessages } = await validate_products(args.products_to_add, args.products_to_remove));
                    }
                    let feedback = valid ? {"result": "success"} : {"result": "error", "errorMessages": errorMessages};
                    if (valid) {
                        let products_to_add = await lookup_products(args.products_to_add);
                        console.log("args.products_to_add:", args.products_to_add);
                        console.log("products_to_add:", products_to_add);
                        current_cart = await update_products_in_cart(current_cart, products_to_add, args.products_to_remove);                        
                    }
                    await send_message({ "role": "function", "name": 
                        "update_products_in_cart", 
                        "content": JSON.stringify(feedback) }, current_cart, new_messages, stats);
                }
            }
        };
        //console.log("new_messages:", new_messages);        
    }

    const handleSend = async (textContent) => {
        props.setAlert({});
        setSending(true);
        try {
            if (recording) {
                stopRecording();
            }
            if (!props.userId) {                
                throw new Error("User ID required");
            }
            setMessages((m) => [...m, {
                message: textContent,
                sentTime: "just now",                        
                direction: "outgoing",
            }]);
            setMsgInputValue("");
            inputRef.current.focus();
            const start = new Date();
            let new_messages = [];
            let stats = { summary: "", total_tokens: 0, num_calls: 0, user_preferences: "" };
            await send_message({ "role": "user", "content": textContent }, props.cartItems, new_messages, stats);            
            const end = new Date();
            //console.log("setting new_messages:", new_messages);
            setMessages((m) => [...m, ...new_messages]);
            props.setCallInfo(<>
                <b>Tokens for last message:</b> {stats.total_tokens}
                <b>Number of AI calls:</b> {stats.num_calls}
                <b>Time taken:</b> {(end-start)/1000}s<br/>
                <b>User preferences:</b> {stats.user_preferences}<br/>
                <b>Conversation summary:</b> {stats.summary}<br/>
            </>);
        } catch (error) {
            console.log('Error in handleSend:', error);
            props.setAlert({type: "error", message: "" + error});
        }
        setSending(false);
    }

    const handleNewConversation = async () => {
        props.setAlert({});
        setClearing(true);
        try {
            if (!props.userId) {                
                throw new Error("User ID required");
            }            
            refresh_messages(true);
            props.setAlert({type: "success", message: "New conversation started"});
        } catch (error) {
            console.error('Error clearing history:', error);
            props.setAlert({type: "error", message: "" + error});
        }    
        setClearing(false);
    }

  
    return (
        <div>            
            <MainContainer>
                <ChatContainer style={{maxHeightheight: "80vh"}}>
                    <MessageList typingIndicator={<TypingIndicator hidden={!sending} content="one moment please" />}>
                        {messages.map((message, index) => (
                            <Message key={index} model={message} />   
                        ))}
                    </MessageList>
                    <div as={MessageInput} style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px dashed #d1dbe4"
                        }}>
                            <MessageInput ref={inputRef} onChange={msg => setMsgInputValue(msg)} 
                            value={msgInputValue} sendButton={false} attachButton={false} onSend={handleSend} style={{
                                flexGrow: 1,
                                borderTop: 0,
                                flexShrink: "initial"
                            }} />                                
                            <SendButton onClick={() => handleSend(msgInputValue)} disabled={msgInputValue.length === 0} style={{
                                fontSize: "1.2em",
                                marginLeft: 0,
                                paddingLeft: "0.2em",
                                paddingRight: "0.2em"
                            }} />
                            <Button icon={<FontAwesomeIcon icon={faMicrophone} />} onClick={() => handleRecord()} style={{
                                fontSize: "1.2em",
                                paddingLeft: "0.2em",
                                paddingRight: "0.2em",
                                color: recording ? "red" : ""
                            }} />
                            <Button onClick={() => handleNewConversation()} style={{
                                fontSize: "1.2em",
                                paddingLeft: "0.2em",
                                paddingRight: "0.2em",
                                color: "green",
                                disabled: clearing
                            }} >New Conversation</Button>
                    </div>                                            
                </ChatContainer>
            </MainContainer>
            
        </div>
        
    );
  };
  
  export default Chat;
  