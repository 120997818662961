import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import userContext from '../UserContext';

const Sidebar = (props) => {
    const location = useLocation();
    
    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
        <CDBSidebar textColor="#fff" backgroundColor="#333">
            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
                <a href="./" className="text-decoration-none" style={{ color: 'inherit' }}>
                    Shopping AI<br/>Admin Portal
                </a>
                <br/>
                {userContext.email}
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
                <CDBSidebarMenu>
                    <NavLink to="./">
                        <CDBSidebarMenuItem active={(location.pathname === "/")} icon="comment">Chat</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="./prompts">
                        <CDBSidebarMenuItem active={(location.pathname === "/prompts")} icon="list">Prompts</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="./api">
                        <CDBSidebarMenuItem active={(location.pathname === "/api")} icon="book">API</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="./conversation_log">
                        <CDBSidebarMenuItem active={(location.pathname === "/conversation_log")} icon="table">Logs</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="./users">
                        <CDBSidebarMenuItem active={(location.pathname === "/users")} icon="users">Users</CDBSidebarMenuItem>
                    </NavLink>
                    <NavLink to="./vector_db">
                        <CDBSidebarMenuItem active={(location.pathname === "/vector_db")} icon="database">Vector DB</CDBSidebarMenuItem>
                    </NavLink>
                    <hr/>
                    <NavLink to="./connect_demo">
                        <CDBSidebarMenuItem active={(location.pathname === "/connect_demo")} icon="phone">Connect Demo</CDBSidebarMenuItem>
                    </NavLink>
                </CDBSidebarMenu>
            </CDBSidebarContent>

            <CDBSidebarFooter>
                <CDBSidebarMenu>
                    <NavLink to="" onClick={() => props.onLogout()}>
                        <CDBSidebarMenuItem><FontAwesomeIcon className="side-icon" icon={faSignOut} />Logout</CDBSidebarMenuItem>
                    </NavLink>
                </CDBSidebarMenu>
            </CDBSidebarFooter>
        </CDBSidebar>
        </div>
    );
};

export default Sidebar;