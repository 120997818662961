import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import ChatPage from './pages/ChatPage';
import PromptPage from './pages/PromptPage';
import ApiPage from './pages/ApiPage';
import ConversationLogPage from './pages/ConversationLogPage';
import UsersPage from './pages/UsersPage';
import VectorDBPage from './pages/VectorDBPage';
import ConnectDemoPage from './pages/ConnectDemoPage';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Sidebar from './nav/Sidebar';
import { createBrowserHistory } from 'history';
import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import userContext from './UserContext';
import CallApi from './components/Api';
import ClipLoader from "react-spinners/ClipLoader";

Amplify.configure({  
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
});

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {
  const [userChecked, setUserChecked] = useState(false);

  const handleLogout = async () => {
    await Auth.signOut();
    userContext.clear();
  }

  useEffect(() => {
    Auth.currentSession().then((session) => {
      let accessToken = session.getAccessToken();      
      CallApi("/user", "GET", {"jwtToken": accessToken.jwtToken}).then((response) => {          
          userContext.email = response.email;
          userContext.jwtToken = accessToken.jwtToken;
          userContext.api_key = response.api_key;
          userContext.openai_key = response.openai_key;
          setUserChecked(true);
        }).catch((error) => {
          console.log("error in get user:", error);
          handleLogout();
        });
    });
    
  }, []);

  if (!userChecked) {
    return (
      <ClipLoader loading={true} size={250} aria-label="Loading..." />
    );
  }
  
  return (
    <div className="d-flex h-100">
      <HashRouter>
        <div className="col-auto">
          {<Sidebar onLogout={handleLogout} />}
        </div>
        <div className="container-fluid h-100" style={{padding: "20px", overflowY: "scroll"}}>          
          <Routes>
            <Route path="/" element={ <ChatPage /> } />
            <Route path="/prompts" element={ <PromptPage /> } />
            <Route path="/api" element={ <ApiPage /> } />
            <Route path="/conversation_log" element={ <ConversationLogPage /> } />
            <Route path="/users" element={ <UsersPage /> } />
            <Route path="/vector_db" element={ <VectorDBPage /> } />
            <Route path="/connect_demo" element={ <ConnectDemoPage /> } />
          </Routes>          
        </div>
      </HashRouter>      
    </div>
  );
}

export default withAuthenticator(App, {  hideSignUp: true });
