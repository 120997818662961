import React, { useState } from "react";
import UsersIndex from "../components/UsersIndex";
import UsersAdd from "../components/UsersAdd";

const UsersPage = () => {
  const [view, setView] = useState("index");  

  const switchView = (view) => {
    switch (view) {            
      case "create":
        return <UsersAdd setView={setView} />;
      default:
        return <UsersIndex setView={setView} />;
    }
  }

  return (    
    <div className="row h-100">      
      {switchView(view)}
    </div>
  );
};

export default UsersPage;