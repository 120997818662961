const userContext = {
    email: null,
    jwtToken: null,
    api_key: null,
    openai_key: null,
    clear: () => {
        userContext.email = null;
        userContext.jwtToken = null;
        userContext.api_key = null;
        userContext.openai_key = null;
    }
};

export default userContext;