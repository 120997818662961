import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CallApi from "../components/Api";
import Alert from "./Alert";

const ConversationLogIndex = (props) => {
    const [data, setData] = useState([]);
    const [pageKeys, setPageKeys] = useState([null]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userId, setUserId] = useState("");
    const [alert, setAlert] = useState({});

    const search = async (LastEvaluatedKey=null) => {
        let params = {};
        if (startDate) {
            params.start_timestamp = startDate.getTime() / 1000;
        }
        if (endDate) {
            params.end_timestamp = endDate.getTime() / 1000;
        }
        if (userId) {
            params.user_id = userId;
        }
        if (LastEvaluatedKey) {
            params.LastEvaluatedKey = LastEvaluatedKey;
        }
        try {
            const response = await CallApi("/user_conversation", "GET", params);
            setData(response.Items);
            if (response.LastEvaluatedKey) {
                setPageKeys([...pageKeys, response.LastEvaluatedKey]);
            }
        } catch (error) {
            setAlert({ type: "error", message: error.message });
        }
    };

    useEffect(() => {
        search();
    }, []);

    const get_footer_buttons = (key, index) => {
        let label = index;
        if (index === pageKeys.length-1)
        label = "Next";
        if (index < pageKeys.length-1 || key) {
        return (
            <button key={index} onClick={() => search(key)} className="btn btn-primary">
            {label}
            </button>
        );
        }
        return null;
    }

    return (
        <div className="col-12">
            <h1>Conversation Logs</h1>
            <Alert alert={alert} />
            <label>User ID</label>&nbsp;
            <div className="inline-block">
            <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} className="form-control inline-block" placeholder="User ID" />
            </div>&nbsp;&nbsp;
            <label>From</label>&nbsp;
            <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />&nbsp;&nbsp;
            <label>To</label>&nbsp;
            <DatePicker className="form-control" selected={endDate} onChange={(date) => setEndDate(date)} />&nbsp;&nbsp;
            <button onClick={() => search()} className="btn btn-primary">Apply</button>
            <br/><br/>
            <table className="table table-bordered table-striped">
            <thead>
                <tr>
                <th>Conversation ID</th>
                <th>User ID</th>
                <th>Duration</th>
                <th>Start</th>
                <th>End</th>
                <th>Tokens</th>
                <th>AI calls</th>
                <th>Summary</th>
                <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                <tr key={index}>
                    <td>
                        <button className="btn btn-link" onClick={() => { props.setSelectedConversation({
                                user_id: item?.user_id?.S,
                                conversation_id: item?.conversation_id?.S,
                                start_timestamp: item?.start_timestamp?.N,
                                last_timestamp: item?.last_timestamp?.N,
                                }); 
                                props.setView("view");
                            }}>
                            {item?.conversation_id?.S}
                        </button>
                    </td>
                    <td>{item?.user_id?.S}</td>
                    <td>{parseInt((parseInt(item?.last_timestamp?.N) - parseInt(item?.start_timestamp?.N))/60)}m</td>
                    <td>{new Date((item?.start_timestamp?.N || 0) * 1000).toLocaleString()}</td>
                    <td>{new Date((item?.last_timestamp?.N || 0) * 1000).toLocaleString()}</td>
                    <td>{item?.total_tokens?.N}</td>
                    <td>{item?.num_calls?.N}</td>
                    <td>{item?.summary?.S}</td>
                    <td>{item?.status?.S}</td>
                </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                <td colSpan="7">
                    {pageKeys.map((key, index) => (
                        get_footer_buttons(key, index)
                    ))}                    
                </td>
                </tr>
            </tfoot>
            </table>
        </div>
    );
  };
  
  export default ConversationLogIndex;
  