import React, { useState } from "react";
import CallApi from "../components/Api";
import Alert from "./Alert";

const UsersAdd = (props) => {
    const [email, setEmail] = useState("");    
    const [alert, setAlert] = useState({});

    const handleCreate = async () => {        
        try {
            let resp = await CallApi("/users", "POST", {email: email});
            console.log("resp", resp);
            props.setView("index");
        }
        catch (error) {
            setAlert({type: "error", message: error.message});
        }        
    }

    return (
        <div className="col-12">            
            <h1>New User</h1>
            <Alert alert={alert} /><br/>
            <form onSubmit={props.handleSubmit}>
                <div className="form-group inline-block">
                    <label htmlFor="email">Email</label>
                    <input type="text" className="form-control" id="email"
                        placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <small id="usernameHelp" className="form-text text-muted">Invitation email will be sent on creation.</small>
                </div>
                <br/><br/>
                <button onClick={() => handleCreate()} className="btn btn-primary">Create</button>&nbsp;&nbsp;
                <button onClick={() => props.setView("index")} className="btn btn-secondary">Cancel</button><br/>
            </form>            
        </div>
    );
  };
  
  export default UsersAdd;
  