const Alert = (props) => {
    
    let alertClass = "alert-info";
    if (props?.alert?.type === "error") {
        alertClass = "alert-danger";
    }
    else if (props?.alert?.type === "success") {
        alertClass = "alert-success";
    }        
    
    return (        
        <div hidden={!props?.alert?.type || !props?.alert?.message} 
            className={"alert " + alertClass} role="alert">
            {props?.alert?.message}
        </div>        
    );
};

export default Alert;