import axios from "axios";
import userContext from "../UserContext";

const CallApi = async (path, method, body, base_url=process.env.REACT_APP_CONVERSATION_API_URL, api_key=null) => { 
    ///console.log("request:", body);
    let headers = {};
    if (api_key) {
        headers["x-api-key"] = api_key;
    } else if (userContext.api_key) {
        headers["x-api-key"] = userContext.api_key;
    }
    let response = null;
    let url = base_url + path;    
    const encode_body = (url, body) => {
        // get, delete dont take a body
        // encode body as query string
        if (body) {
            url += "?" + Object.keys(body).map(key => key + '=' + body[key]).join('&');
        }
        return url;
    }
    if (method === "GET") {        
        url = encode_body(url, body);
        response = await axios.get(url, {headers: headers});
    } else if (method === "POST") {
        response = await axios.post(url, body, {headers: headers});
    } else if (method === "DELETE") {
        url = encode_body(url, body);
        response = await axios.delete(url, {headers: headers});
    }
    //console.log("response:", response.data);
    return response.data
}

export default CallApi;