import React, { useState } from "react";
import CallApi from "../components/Api";
import Alert from "../components/Alert";

const ConnectDemoPage = () => {  
  const [alert, setAlert] = useState({});
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");  
  
  const submit = async () => {
    setAlert({});           
    try {
      if (!phone) {
        throw new Error("Please enter Phone nr.");
      }
      if (!name) {
        throw new Error("Please enter Name");
      }
      const prompts = (await CallApi("/prompt", "GET"))?.prompts;      
      const CONNECT_DEMO_GREETING = prompts.find(x => x?.name?.S === "CONNECT_DEMO_GREETING")?.value?.S;
      const CONNECT_DEMO_MESSAGES = prompts.find(x => x?.name?.S === "CONNECT_DEMO_MESSAGES")?.value?.S;
      let messages = JSON.parse(CONNECT_DEMO_MESSAGES);
      for (let i = 0; i < messages.length; i++) {
        if (messages[i]?.content) {
          messages[i].content = messages[i]?.content.replace("{name}", name)
            .replace("{company}", company)
            .replace("{title}", title);
        }
      }
      let greeting = CONNECT_DEMO_GREETING.replace("{name}", name)
        .replace("{company}", company)
        .replace("{title}", title);
      if (phone.startsWith("0")) {
        phone = "+27" + phone.substring(1);
      }
      let params = {
        "name": name,
        "company": company,
        "title": title,        
        "callback_url": "",
        "callback_token": "",
        "phone_number": phone.trim(),
        "greeting": greeting,
        "messages": messages,
        "closing_method": "phrase",
        "closing_phrase": "goodbye",
        "closing_attribute": "conv_over",
        "silence_prompt": "Anything else I can help with?",
        "face_auth_required": "0",
        "face_auth_url": "https://demo-onboarding.incodesmile.com/tangents234/hello",
        "skip_agent": "0"
      }; 
      const response = await CallApi("/trigger-outbound-flow", "POST", params, 
        "https://qx3gucyfsf.execute-api.af-south-1.amazonaws.com", "3f931a9158d94a3598c45ac866d5f631");
      setAlert({type: "success", message: "Call submitted successfully."});
    } catch (error) {
      setAlert({type: "error", message: error.message});
    }
};

  return (    
    <div className="row h-100">      
      <div className="col-6">
        <h1>Connect AI Demo</h1>
        <Alert alert={alert} />
        <label>Phone nr.</label>
        <br/>
        <div className="inline-block">
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} 
            className="form-control" placeholder="Phone nr." />              
        </div>
        <br/><br/>
        
        
        <label>Name</label><br/>        
        <div className="inline-block">
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} 
            className="form-control" placeholder="Name" />          
        </div>
        <div className="inline-block">
          &nbsp;&nbsp;<small className="form-text text-muted">{"{name}"}</small>
        </div>

        <br/><br/>

        <label>Company Name</label><br/>
        <div className="inline-block">
          <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} 
            className="form-control" placeholder="Company Name" />          
        </div>
        <div className="inline-block">
          &nbsp;&nbsp;<small className="form-text text-muted">{"{company}"}</small>
        </div>

        <br/><br/>

        <label>Job Title</label><br/>
        <div className="inline-block">
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} 
            className="form-control" placeholder="Job Title" />          
        </div>
        <div className="inline-block">
          &nbsp;&nbsp;<small className="form-text text-muted">{"{title}"}</small>
        </div>

        <br/><br/>
        <button onClick={() => submit()} className="btn btn-primary">Submit Call</button>
        <br/>            
      </div>
    </div>
  );
};

export default ConnectDemoPage;