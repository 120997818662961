import React, { useState, useEffect } from "react";
import userContext from "../UserContext";

const ApiPage = () => {
  const [apiDoc, setApiDoc] = useState('');  

  useEffect(() => {
    fetch("./ApiDoc.html").then((response) => response.text()).then((text) => {      
      setApiDoc(text);      
    })
  }, []);

  return (    
    <div className="row" style={{overflow: "scroll", paddingLeft: "15pt"}}>
      <h1>Shopping Assistant API</h1>

      <div>
        <b>URL</b>:<br/>
        {process.env.REACT_APP_CONVERSATION_API_URL}<br/>
        <b>Key</b>:<br/>
        {userContext.api_key}<br/>
        <b>Swagger Docs</b>:<br/>
        <a href={process.env.REACT_APP_CONVERSATION_API_URL+"/docs"} target="_blank">{process.env.REACT_APP_CONVERSATION_API_URL}/docs</a>
        <br/><br/>
      </div>
      
      <br/><br/>
      <div dangerouslySetInnerHTML={{__html:apiDoc}} />
        
    </div>
  );
};

export default ApiPage;