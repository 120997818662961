import React, { useState, useEffect } from "react";
import CallApi from "../components/Api";
import Alert from "./Alert";

const UsersIndex = (props) => {
    const [data, setData] = useState([]);
    const [pageKeys, setPageKeys] = useState([null]);    
    const [filter, setFilter] = useState("");
    const [alert, setAlert] = useState({});

    const search = async (PaginationToken=null) => {
        setAlert({});
        let params = {};        
        if (filter) {
            params.filter = filter;
        }
        if (PaginationToken) {
            params.PaginationToken = PaginationToken;
        }
        try {
            const response = await CallApi("/users", "GET", params);
            setData(response.Users);
            if (response.PaginationToken) {
                setPageKeys([...pageKeys, response.PaginationToken]);
            }
        }
        catch (error) {
            setAlert({type: "error", message: error.message});
        }  
    };

    const handleDelete = async (username) => {
        setAlert({});
        if (window.confirm("Are you sure?")) {
            try {
                await CallApi("/users", "DELETE", {username: username});                
                search();                
            }
            catch (error) {
                setAlert({type: "error", message: error.message});
            }
        }
    }

    useEffect(() => {
        search();
    }, []);

    const get_footer_buttons = (key, index) => {
        let label = index;
        if (index === pageKeys.length-1)
        label = "Next";
        if (index < pageKeys.length-1 || key) {
        return (
            <button key={index} onClick={() => search(key)} className="btn btn-primary">
            {label}
            </button>
        );
        }
        return null;
    }

    return (
        <div className="col-12">            
            <h1>Users</h1>
            <Alert alert={alert} /><br/>
            <button onClick={() => props.setView("create")} className="btn btn-primary">New User</button><br/><br/>
            <label>Filter</label>&nbsp;
            <div className="inline-block">
            <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control inline-block" placeholder="Username" />
            </div>&nbsp;&nbsp;            
            <button onClick={() => search()} className="btn btn-primary">Apply</button>
            <br/><br/>
            <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Email</th>                    
                    <th>User Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                <tr key={index}>                    
                    <td>{item?.Attributes.find(v => v.Name === "email")?.Value}</td>                    
                    <td>{item?.UserStatus}</td>
                    <td>
                        <button onClick={() => handleDelete(item?.Username)}
                            className="btn btn-danger" confirm="Are you sure?">Delete</button>
                    </td>
                </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                <td colSpan="7">
                    {pageKeys.map((key, index) => (
                        get_footer_buttons(key, index)
                    ))}                    
                </td>
                </tr>
            </tfoot>
            </table>
        </div>
    );
  };
  
  export default UsersIndex;
  