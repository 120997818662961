import React, { useState } from "react";
import CallApi from "../components/Api";
import Alert from "../components/Alert";

const VectorDBPage = () => {
  const [data, setData] = useState([]);
  const [collection_name, setCollectionName] = useState("product_catalog");
  const [query_text, setQueryText] = useState("instant yeast");
  const [n_results, setNResults] = useState(5);
  const [where, setWhere] = useState([]);
  const [max_distance, setMaxDistance] = useState(0.5);
  const [alert, setAlert] = useState({});
  
  const query = async () => {
    setAlert({});
    let params = {};
    if (collection_name) {
        params.collection_name = collection_name;
    }
    if (query_text) {
        params.query_text = query_text;
    }
    if (n_results) {
        params.n_results = n_results;
    }
    if (where) {
        let where_dict = {};
        where.forEach((item) => {
          where_dict[item["name"]] = item["value"];
        });
        params.where = where_dict;
    }
    if (max_distance) {
        params.max_distance = max_distance;
    }
    try {
      const response = await CallApi("/query_vector_db", "POST", params);
      setData(response);    
    } catch (error) {
      setAlert({type: "error", message: error.message});
    }
};

  return (    
    <div className="row h-100">      
      <div className="col-12">
            <h1>Vector DB</h1>
            <Alert alert={alert} />
            <label>Collection</label>&nbsp;
            <div className="inline-block">
              <select value={collection_name} onChange={(e) => setCollectionName(e.target.value)} className="form-control">
                <option value="product_catalog">product_catalog</option>
              </select>
            </div>&nbsp;&nbsp;
            <label>Query Text</label>&nbsp;
            <div className="inline-block">
              <input type="text" value={query_text} onChange={(e) => setQueryText(e.target.value)} 
                className="form-control" placeholder="Query Text" />
            </div>&nbsp;&nbsp;
            <label>Limit</label>&nbsp;
            <div className="inline-block">
              <input type="numeric" value={n_results} onChange={(e) => setNResults(e.target.value)} 
                className="form-control" placeholder="Limit" />
            </div>&nbsp;&nbsp;
            <label>Max Distance</label>&nbsp;
            <div className="inline-block">
              <input type="numeric" value={max_distance} onChange={(e) => setMaxDistance(e.target.value)} 
                className="form-control" placeholder="Max Distance" />
            </div>&nbsp;&nbsp;
            <br/>
            <label>Metadata Filters</label><br/>
            {where.map((item, index) => (
              <>
                <div key={index}>
                  <div className="inline-block">
                    <input type="text" 
                      className="form-control inline-block"
                      value={item["name"]} onChange={(e) => {
                      let new_where = [...where];
                      new_where[index]["name"] = e.target.value;
                      setWhere(new_where);
                      }} />
                  </div>
                  <div className="inline-block">=</div>
                  <div className="inline-block">
                    <input type="text" 
                      className="form-control inline-block"
                      value={item["value"]} onChange={(e) => {
                      let new_where = [...where];
                      new_where[index]["value"] = e.target.value;
                      setWhere(new_where);
                    }} />
                  </div>&nbsp;&nbsp;
                  <div className="inline-block">
                    <button className="btn btn-outline-danger" onClick={() => {
                      let new_where = [...where];
                      new_where.splice(index, 1);
                      setWhere(new_where);
                      }}>X</button>
                  </div>
                </div>
                <br/>
              </>
            ))}            
            <button onClick={() => {
              let new_where = [...where];
              new_where.push({name: "", value: ""});
              setWhere(new_where);
              }} className="btn btn-outline-success">Add Metadata Filter</button>
            <br/><br/>
            
            <button onClick={() => query()} className="btn btn-primary">Query</button>
            <br/><br/>
            <table className="table table-bordered table-striped">
              <thead>
                  <tr>
                    <th key="distance">Distance</th>
                    {Object.keys((data?.metadatas?.at(0) || []).at(0) || []).map((key, index) => (
                      <th key={index}>{key}</th>
                    ))}
                  </tr>
              </thead>
              <tbody>
                  {(data?.metadatas?.at(0) || []).map((item, index) => (
                    <tr key={index}>
                        <td key="distance">{(data?.distances?.at(0) || []).at(index)}</td>
                        {Object.keys(item).map((key, index2) => (
                          <td key={index2}>{item[key]}</td>
                        ))}                    
                    </tr>
                  ))}
              </tbody>            
            </table>
        </div>
    </div>
  );
};

export default VectorDBPage;