import React, { useState, useEffect } from "react";
import ConversationLogIndex from "../components/ConversationLogIndex";
import ConversationLogView from "../components/ConversationLogView";

const ConversationLogPage = () => {
  const [view, setView] = useState("index");
  const [selectedConversation, setSelectedConversation] = useState(null);

  const switchView = (view) => {
    switch (view) {            
      case "view":
        return <ConversationLogView setView={setView} selectedConversation={selectedConversation} />;
      default:
        return <ConversationLogIndex setView={setView} setSelectedConversation={setSelectedConversation} />;
    }
  }

  return (    
    <div className="row h-100">      
      {switchView(view)}
    </div>
  );
};

export default ConversationLogPage;