
const Cart = (props) => {
    return (
        <div>
            <h2>Cart</h2>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Qty</th>
                        <th>Code</th>
                        <th>Label</th>
                        <th>Manufacturer</th>
                        <th>Brand</th>
                        <th>Description</th>
                        <th></th>
                    </tr>                    
                </thead>
                <tbody>
                    {props.cartItems.map((item, index) => (
                        <tr key={index}>
                            <td><b>{item.quantity}x</b></td>
                            <td>{item.product_code}</td>
                            <td>{item.label}</td>
                            <td>{item.manufacturer}</td>
                            <td>{item.brand}</td>
                            <td>{item.description}</td>
                            <td><button className="btn btn-danger" 
                                onClick={() => props.setCartItems(props.cartItems.filter((cartItem) => cartItem.product_code !== item.product_code))}>
                                    Remove</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        
    );
  };
  
  export default Cart;
  