import React, { useState } from "react";
import Chat from '../components/Chat';
import Cart from '../components/Cart';
import Alert from "../components/Alert";
import Cookies from 'universal-cookie';
import userContext from "../UserContext";
import CallApi from "../components/Api";

const ChatPage = () => {
  const cookies = new Cookies();
  const [cartItems, setCartItems] = useState([]);
  const [userId, setUserId] = useState(userContext.email || '');
  const [alert, setAlert] = useState({});
  const [callInfo, setCallInfo] = useState(<></>);  

  const handleUserId = (v) => {
    setUserId(v);
    cookies.set('userId', v, { path: '/' });
  }

  const handleClearUserPreferences = async () => {
      setAlert({});      
      try {
          if (!userId) {                
              throw new Error("User ID required");
          }
          await CallApi("/user_preferences", "POST", {"user_id": userId, "user_preferences": ""});
          setAlert({type: "success", message: "Preferences cleared"});            
      } catch (error) {
          console.error('Error clearing preferences:', error);
          setAlert({type: "error", message: error.message});
      }
  }

  return (
    <div className="row h-100">
      <div className="col-md-6" style={{display: "flex",  flexDirection: "column",  height: "100%"}}>
        <Alert alert={alert} />
        <input type="text" className="form-control" placeholder="User ID"
          value={userId} onChange={e => handleUserId(e.target.value)} />                   
        <Chat userId={userId} cartItems={cartItems} 
          setCartItems={setCartItems} 
          setAlert={setAlert}
          setCallInfo={setCallInfo} />
      </div>
      <div className="col-md-6 h-100">        
        <Cart userId={userId} cartItems={cartItems} setCartItems={setCartItems} />
        <div className="col-md-6" style={{display: "flex",  flexDirection: "column"}}>
          <div className="inline-block">
            <button onClick={() => handleClearUserPreferences()} className="btn btn-outline-danger">Clear User Preferences</button>
          </div>
          {callInfo}          
        </div>
      </div>      
    </div>      
  );
};

export default ChatPage;